<ng-container>
  <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>
  <!-- nested menus -->
  <a
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    [attr.href]="item.url"
    (click)="itemClick($event)"
    appPermission
    [resource]="item?.resourceName"
    [enableActions]="item?.right"
    [isPublicView]="item?.isPublic"
    [pTooltip]="item.label"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text font-bold">{{ item.label }}</span> &nbsp;&nbsp;
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
  </a>
  <div
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    appPermission
    [resource]="item?.resourceName"
    [enableActions]="item?.right"
    [isPublicView]="item?.isPublic"
    class="my-4"
  ></div>
  <!-- normal menus -->
  <a
    *ngIf="item.routerLink && !item.items && item.visible !== false"
    appPermission
    [resource]="item?.resourceName"
    [enableActions]="item?.right"
    [isPublicView]="item?.isPublic"
    (click)="itemClick($event)"
    [ngClass]="item.class"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="item.routerLinkOptions || { exact: true }"
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [pTooltip]="item.label"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
  </a>

  <ul *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation">
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li app-menu-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    </ng-template>
  </ul>
</ng-container>
