import { Injectable } from '@angular/core';
// import * as dayjs from 'dayjs';
import { Translation } from 'primeng/api';
import {User} from "../../modules/user/interfaces";
const dayjs = require("dayjs")

@Injectable({
  providedIn: 'root',
})
export class GenericFunctions {
  constructor() { }
  /**
   * Para que los calendarios primeNG se muestren en español
   */
  public dateEsConfig(): Translation {
    return {
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      today: 'Hoy',
      clear: 'Borrar'
    };
  }

  public parseData(
    data: Array<any>,
    keys: Array<any>,
    f: Array<any>
  ): Array<any> {
    data.forEach((element) => {
      // tslint:disable-next-line: forin
      for (const key in element) {
        keys.forEach((k, index) => {
          if (key === k) {
            if (typeof f[index] === 'string') {
              element[key] = dayjs(element[key]).format('DD/MM/YY');
            } else {
              const temp = f[index].find((e: any) => e.value === element[key]);
              element[key] = temp.label;
            }
          }
        });
      }
    });
    return data;
  }

    public static getUserFullName(user: User, abbr = true) {
        const { primerNombre, segundoNombre, apellidoMaterno, apellidoPaterno} = user || {};

        if(!primerNombre || !apellidoPaterno) return '';
        if(abbr)
            return `${primerNombre} ${this.abbrName(segundoNombre)} ${apellidoPaterno} ${this.abbrName(apellidoMaterno)}`;

        return `${primerNombre} ${segundoNombre || ''} ${apellidoPaterno} ${apellidoMaterno}`;
    }

    public static abbrName(name: string = '') {
        if (!name) return '';

        return `${name.charAt(0).toUpperCase()}.`;
    }

  public getSwalDefaults(): any {
    return {
      text: 'Está seguro que desea guardar',
      type: 'question',
      position: 'center',
      showCancelButton: true,
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      heightAuto: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      cancelButtonClass: 'btn btn-lg btn-default',
      confirmButtonClass: 'btn btn-lg btn-primary ml-2',
      icon: 'question',
    };
  }

  static SortObjectArray(list:any, field: string): any {
      try {
          return list.sort((a:any, b:any) => {
              const tituloA = a[field].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              const tituloB = b[field].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              return tituloA.localeCompare(tituloB);
          });
      } catch (err) {
          return list;
      }
  }
}
