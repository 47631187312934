<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="home">
    <div class="text-white text-left flex justify-content-center align-items-center">
      <img class="hidden md:block" src="assets/img/logo_inra_horizonal.png" alt="logo" />
      <!-- <img class='hidden md:block' src="assets/img/logo-reverse.png" alt="logo">
          <img class='ml-2 block md:hidden' src="assets/img/logo.png" alt="logo" style='height: 50px'> -->
    </div>
  </a>

  <div class="flex justify-content-between flex-grow-1 align-items-center">
    <div class="flex">
      <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
      </button>

      <button
        #topbarmenubutton
        class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>
    </div>
    <div>
      <span class="text-base md:text-xl lg:text-2xl xl:text-3xl uppercase font-bold">
        Sistema Nacional de Catastro Rural Ambiental
      </span>
    </div>

    <div
      #topbarmenu
      class="rounded layout-topbar-menu hover:bg-green-500 hover:text-white p-1"
      [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }"
    >
      <button class="p-link p-1" (click)="menu.toggle($event)" style="min-width: 150px">
        <div class="m-0 w-full flex gap-2 justify-content-end align-items-center layout-topbar-button">
          <div class="layout-topbar-button m-0">
            <i class="pi pi-user"></i>
          </div>
          <div>
            <label>{{ getCurrentUserName() }}</label>
            <!-- <p class="text-sm">{{getCurrentRole}}</p> -->
          </div>
        </div>
      </button>

      <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items"></p-menu>
    </div>
  </div>
</div>
