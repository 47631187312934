import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminTemplateComponent } from './templates/admin-template/admin-template.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { NotfoundComponent } from './shared/pages/notfound/notfound.component';
import { SystemPermissionGuard } from './shared/guards/system-permission.guard';
// verifyPermission comes from permissionTypes review it to set a value
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) },
  { path: 'error', loadChildren: () => import('./shared/pages/error/error.module').then((m) => m.ErrorModule) },
  { path: 'access', loadChildren: () => import('./shared/pages/access/access.module').then((m) => m.AccessModule) },
  {
    path: '',
    component: AdminTemplateComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [SystemPermissionGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'user',
        data: {
          moduleNameCode: 'USUARIOS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'role',
        data: {
          moduleNameCode: 'ROLES',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/role/role.module').then((m) => m.RoleModule),
      },
      {
        path: 'system-acl',
        data: {
          moduleNameCode: 'PERMISOS_SISTEMA',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/permiso-sistema/permiso-sistema.module').then((m) => m.PermisoSistemaModule),
      },
      {
        path: 'log-activity',
        data: {
          moduleNameCode: 'LOG_ACTIVIDADES',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/log-actividad/log-actividad.module').then((m) => m.LogActividadModule),
      },
      {
        path: 'information-resource-type',
        data: {
          moduleNameCode: 'RECURSOS_INFORMACION',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/information-resource-type/information-resource-type.module').then(
            (m) => m.InformationResourceTypeModule
          ),
      },
      {
        path: 'information-resource',
        data: {
          moduleNameCode: 'RECURSOS_INFORMACION',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/information-resource/information-resource.module').then((m) => m.InformationResourceModule),
      },
      {
        path: 'web-map-service',
        data: {
          moduleNameCode: 'SERVICIOS_MAPAS_WEB',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/web-map-service/web-map-service.module').then((m) => m.WebMapServiceModule),
      },
      {
        path: 'cartographic-resource/simple',
        data: {
          moduleNameCode: 'RECURSOS_CARTOGRAFICOS',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/cartographic-resource/cartographic-resource.module').then(
            (m) => m.CartographicResourceModule
          ),
      },
      {
        path: 'sld',
        data: {
          moduleNameCode: 'RECURSOS_CARTOGRAFICOS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/sld/sld.module').then((m) => m.SldModule),
      },
      {
        path: 'cartographic-resource/objects-catalog',
        data: {
          moduleNameCode: 'RECURSOS_CARTOGRAFICOS',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/objects-catalog/objects-catalog.module').then((m) => m.ObjectsCatalogModule),
      },
      {
        path: 'theme',
        data: {
          moduleNameCode: 'TEMAS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'thematics',
        data: {
          moduleNameCode: 'TEMATICAS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/thematic/thematic.module').then((m) => m.ThematicModule),
      },
      {
        path: 'environmental-component',
        data: {
          moduleNameCode: 'COMPONENTES_AMBIENTALES',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/environmental-component/environmental-component.module').then(
            (m) => m.EnvironmentalComponentModule
          ),
      },
      {
        path: 'indicator',
        data: {
          moduleNameCode: 'INDICADORES',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/indicator/indicator.module').then((m) => m.IndicatorModule),
      },
      {
        path: 'cartographic-resource/indicator',
        data: {
          moduleNameCode: 'RECURSOS_CARTOGRAFICOS_INDICADOR',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/cartographic-resource/cartographic-resource.module').then(
            (m) => m.CartographicResourceModule
          ),
      },
      {
        path: 'land-environmental-analysis',
        data: {
          moduleNameCode: 'ANALISIS_AMBIENTAL',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/land-environmental-analysis/land-environmental-analysis.module').then(
            (m) => m.LandEnvironmentalAnalysisModule
          ),
      },
      {
        path: 'environmental-compliance-certificate',
        data: {
          moduleNameCode: 'ANALISIS_AMBIENTAL',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/environment-compliance-certificates/environment-compliance-certificates.module').then(
            (m) => m.EnvironmentComplianceCertificatesModule
          ),
      },
      {
        path: 'indicator-loader-layer/planning',
        data: {
          moduleNameCode: 'ASIGNACION_CARGADO_DATOS_INDICADOR',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/indicator-loader-layer/indicator-loader-layer.module').then(
            (m) => m.IndicatorLoaderLayerModule
          ),
      },
      {
        path: 'indicator-loader-layer/loaded',
        data: {
          moduleNameCode: 'CARGADO_DATOS_INDICADOR',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/indicator-loader-layer/indicator-loader-layer.module').then(
            (m) => m.IndicatorLoaderLayerModule
          ),
      },
      {
        path: 'sensor',
        data: {
          moduleNameCode: 'IMG_SATELITE',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/sensor/sensor.module').then((m) => m.SensorModule),
      },
      {
        path: 'satellite-image',
        data: {
          moduleNameCode: 'IMG_SATELITE',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/satellite-image/satellite-image.module').then((m) => m.SatelliteImageModule),
      },
      {
        path: 'visits',
        data: {
          moduleNameCode: 'VISITAS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/visits/visits.module').then((m) => m.VisitsModule),
      },
      {
        path: 'form-fes',
        data: {
          moduleNameCode: 'FES',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/form-fes/form-fes.module').then((m) => m.FormFesModule),
      },
      {
        path: 'fes-compliance-certificate',
        data: {
          moduleNameCode: 'FES',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/fes-compliance-certificates/fes-compliance-certificates.module').then(
            (m) => m.FesComplianceCertificatesModule
          ),
      },
      {
        path: 'sustainable-monitoring',
        data: {
          moduleNameCode: 'MONITOREO_SOSTENIBLE',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/sustainable-monitoring/sustainable-monitoring.module').then(
            (m) => m.SustainableMonitoringModule
          ),
      },
      {
        path: 'sustainable-monitoring/revision',
        data: {
          moduleNameCode: 'REVISION_MONITOREO_SOSTENIBLE',
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/sustainable-monitoring/sustainable-monitoring.module').then(
            (m) => m.SustainableMonitoringModule
          ),
      },
      {
        path: 'reports',
        data: {
          moduleNameCode: 'MENU_REPORTES',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'config-menu-geovisor',
        data: {
          moduleNameCode: 'GEOVISOR_MENU_CONFIG',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/config-menu-geovisor/config-menu-geovisor.module').then((m) => m.ConfigMenuGeovisorModule),
      },
      {
        path: 'geoconflict',
        data: {
          moduleNameCode: 'GEOCONFLICTOS',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/geoconflict/geoconflict.module').then((m) => m.GeoconflictModule),
      },
    ],
  },
  {
    path: 'internal',
    canActivate: [AuthenticationGuard],
    canActivateChild: [SystemPermissionGuard],
    children: [
      {
        path: 'geovisor',
        data: {
          moduleNameCode: 'GEOVISOR',
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/geovisor/geovisor.module').then((m) => m.GeovisorModule),
      },
    ],
  },
  { path: 'geovisor', loadChildren: () => import('./modules/geovisor/geovisor.module').then((m) => m.GeovisorModule) },
  {
    path: 'public/report',
    loadChildren: () => import('./modules/report-pdf/report-pdf.module').then((m) => m.ReportPdfModule),
  },
  {
    path: 'public/objects-catalog',
    loadChildren: () =>
      import('./modules/objects-catalog-pdf/objects-catalog-pdf.module').then((m) => m.ObjectsCatalogPdfModule),
  },
  {
    path: 'public/fes-compliance-certificate',
    loadChildren: () =>
      import('./modules/fes-compliance-certificates-pdf/fes-compliance-certificates-pdf.module').then(
        (m) => m.FesComplianceCertificatesPdfModule
      ),
  },
  {
    path: 'public/environmental-compliance-certificate',
    loadChildren: () =>
      import('./modules/environment-compliance-certificates-pdf/environment-compliance-certificates-pdf.module').then(
        (m) => m.EnvironmentComplianceCertificatesPdfModule
      ),
  },
  { path: '**', redirectTo: 'NotfoundComponent' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
