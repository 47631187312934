import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/app.layout.service';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService, public permissionService: PermissionService) {}

  ngOnInit(): void {
    this.model = [
      {
        label: 'Menú',
        isPublic: true,
        items: [
          { label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/home'], isPublic: true, class: 'mb-4' },
          {
            label: 'Administración',
            icon: 'pi pi-fw pi-user',
            resourceName: 'USUARIOS_ADMIN_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración de usuarios',
                icon: 'pi pi-fw pi-user',
                resourceName: 'USUARIOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/user'],
              },
              {
                label: 'Administración de Roles',
                icon: 'pi pi-fw pi-list',
                resourceName: 'ROLES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/role'],
              },
              {
                label: 'Administración de Permisos del Sistema',
                icon: 'pi pi-fw pi-th-large',
                resourceName: 'PERMISOS_SISTEMA',
                right: 'canView',
                isPublic: false,
                routerLink: ['/system-acl'],
              },
              {
                label: 'Log de Actividad',
                icon: 'pi pi-fw pi-list',
                resourceName: 'LOG_ACTIVIDADES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/log-activity'],
              },
              // {
              //   label: 'Submenu 1.1',
              //   icon: 'pi pi-fw pi-bookmark',
              //   items: [
              //     { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
              //     { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
              //     { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
              //   ],
              // },
              // {
              //   label: 'Submenu 1.2',
              //   icon: 'pi pi-fw pi-bookmark',
              //   items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }],
              // },
            ],
          },
          {
            label: 'Recursos de Información',
            icon: 'pi pi-fw pi-verified',
            resourceName: 'ADMINISTRACION_RECURSOS_INFORMACION',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Tipos de recursos',
                icon: 'pi pi-fw pi-verified',
                resourceName: 'RECURSOS_INFORMACION',
                right: 'canView',
                isPublic: false,
                routerLink: ['/information-resource-type'],
              },
              {
                label: 'Recursos de Información',
                icon: 'pi pi-fw pi-map-marker',
                resourceName: 'RECURSOS_INFORMACION',
                right: 'canView',
                isPublic: false,
                routerLink: ['/information-resource'],
              },
            ],
          },
          {
            label: 'Modulo IDE',
            icon: 'pi pi-fw pi-map',
            resourceName: 'ADMINISTRACION_RECURSOS_CARTOGRAFICOS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Interoperabilidad',
                icon: 'pi pi-fw pi-globe',
                resourceName: 'SERVICIOS_MAPAS_WEB',
                right: 'canView',
                isPublic: false,
                routerLink: ['/web-map-service'],
              },
              {
                label: 'Recursos Cartográficos',
                icon: 'pi pi-fw pi-map',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/cartographic-resource/simple'],
              },
              {
                label: 'Estilos SLD',
                icon: 'pi pi-code',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sld'],
              },
              {
                label: 'Catálogo de Objetos',
                icon: 'pi pi-book',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/cartographic-resource/objects-catalog'],
              },
            ],
          },
          {
            label: 'Atlas',
            icon: 'pi pi-fw pi-globe',
            resourceName: 'TEMAS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Atlas',
                icon: 'pi pi-fw pi-globe',
                resourceName: 'TEMAS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/theme'],
              },
            ],
          },
          {
            label: 'Tematicas',
            icon: 'pi pi-fw pi-book',
            resourceName: 'TEMATICAS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Tematicas',
                icon: 'pi pi-fw pi-book',
                resourceName: 'TEMATICAS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/thematics'],
              },
            ],
          },
          {
            label: 'Geovisor',
            icon: 'pi pi-fw pi-map',
            resourceName: 'GEOVISOR',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Geovisor interno',
                icon: 'pi pi-fw pi-map',
                resourceName: 'GEOVISOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/internal/geovisor'],
              },
              {
                label: 'Geovisor externo',
                icon: 'pi pi-fw pi-map',
                resourceName: 'GEOVISOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/geovisor'],
              },
              {
                label: 'Configuración Geovisor',
                icon: 'pi pi-fw pi-list',
                resourceName: 'GEOVISOR_MENU_CONFIG',
                right: 'canView',
                isPublic: false,
                routerLink: ['/config-menu-geovisor'],
              },
            ],
          },
          {
            label: 'Indicadores Ambientales',
            icon: 'pi pi-fw pi-cog',
            resourceName: 'INDICADORES_AMBIENTALES',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Componentes ambientales',
                icon: 'pi pi-fw pi-database',
                resourceName: 'COMPONENTES_AMBIENTALES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/environmental-component'],
              },
              {
                label: 'Indicadores',
                icon: 'pi pi-fw pi-stop',
                resourceName: 'INDICADORES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/indicator'],
              },
              {
                label: 'Asignación de Cargado de datos para Indicadores',
                icon: 'pi pi-fw pi-user-edit',
                resourceName: 'ASIGNACION_CARGADO_DATOS_INDICADOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/indicator-loader-layer/planning'],
              },
              {
                label: 'Cargado de datos para Indicadores',
                icon: 'pi pi-fw pi-database',
                resourceName: 'CARGADO_DATOS_INDICADOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/indicator-loader-layer/loaded'],
              },
              {
                label: 'Análisis Ambiental',
                icon: 'pi pi-fw pi-globe',
                resourceName: 'ANALISIS_AMBIENTAL',
                right: 'canView',
                isPublic: false,
                routerLink: ['/land-environmental-analysis'],
              },
              {
                label: 'Catalogo de Mapas de Indicadores',
                icon: 'pi pi-fw pi-book',
                resourceName: 'RECURSOS_CARTOGRAFICOS_INDICADOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/cartographic-resource/indicator'],
              },
            ],
          },
          {
            label: 'Imagenes Satelitales',
            icon: 'pi pi-fw pi-th-large',
            resourceName: 'IMG_SATELITE',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Sensores',
                icon: 'pi pi-fw pi-table',
                resourceName: 'IMG_SATELITE',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sensor'],
              },
              {
                label: 'Imagenes Satelitales',
                icon: 'pi pi-fw pi-image',
                resourceName: 'IMG_SATELITE',
                right: 'canView',
                isPublic: false,
                routerLink: ['/satellite-image'],
              },
            ],
          },
          {
            label: 'Monitoreo Sostenible',
            icon: 'pi pi-fw pi-desktop',
            resourceName: 'MONITOREO_SOSTENIBLE_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Asignación y Adminstración (Monitoreo)',
                icon: 'pi pi-fw pi-desktop',
                resourceName: 'MONITOREO_SOSTENIBLE',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sustainable-monitoring'],
              },
              {
                label: 'Revisión (Monitoreo)',
                icon: 'pi pi-fw pi-desktop',
                resourceName: 'REVISION_MONITOREO_SOSTENIBLE',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sustainable-monitoring/revision'],
              },
            ],
          },
          {
            label: 'FES',
            icon: 'pi pi-fw pi-share-alt',
            resourceName: 'MENU_FES',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Visitas',
                icon: 'pi pi-fw pi-search',
                resourceName: 'VISITAS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/visits'],
              },
              {
                label: 'Análisis FES',
                icon: 'pi pi-fw pi-file-edit',
                resourceName: 'FES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/form-fes'],
              },
              {
                label: 'Reversión FES',
                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                resourceName: 'FES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/form-fes/reversion-fes'],
              },
            ],
          },
          {
            label: 'Geo Conflictos',
            icon: 'pi pi-fw pi-sort-alt-slash',
            resourceName: 'GEOCONFLICTOS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Geo Conflictos',
                icon: 'pi pi-fw pi-sort-alt-slash',
                resourceName: 'GEOCONFLICTOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/geoconflict'],
              },
            ],
          },
          {
            label: 'Certificados',
            icon: 'pi pi-fw pi-file-pdf',
            // Change this
            resourceName: 'MENU_REPORTES',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración de Certificados Cumplimiento FES',
                icon: 'pi pi-fw pi-file-pdf',
                resourceName: 'FES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/fes-compliance-certificate'],
              },
              {
                label: 'Administración de Certificado de Indicadores Ambientales',
                icon: 'pi pi-fw pi-file-pdf',
                resourceName: 'ANALISIS_AMBIENTAL',
                right: 'canView',
                isPublic: false,
                routerLink: ['/environmental-compliance-certificate'],
              },
            ],
          },
          {
            label: 'Reportes',
            icon: 'pi pi-fw pi-file-export',
            resourceName: 'MENU_REPORTES',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Reportes Indicadores Ambientales',
                icon: 'pi pi-fw pi-search',
                resourceName: 'REPORTES_IND_AMB',
                right: 'canView',
                isPublic: false,
                routerLink: ['/reports/indicators'],
              },
              {
                label: 'Reporte de Cumplimiento FES',
                icon: 'pi pi-fw pi-file-edit',
                resourceName: 'REPORTES_CUMP_FES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/reports/fes'],
              },
              //   {
              //     label: 'Dashboard',
              //     icon: 'pi pi-fw pi-file-edit',
              //     resourceName: 'DASHBOARD_REPORTES',
              //     right: 'canView',
              //     isPublic: false,
              //     routerLink: ['/reports/dashboard'],
              //   },
            ],
          },
        ],
      },
      // {
      //     label: 'Get Started',
      //     items: [
      //         {
      //             label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
      //         },
      //         {
      //             label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sinacar-frontend'], target: '_blank'
      //         }
      //     ]
      // }
    ];
  }
}
